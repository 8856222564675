<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import { validationMixin } from "vuelidate";
import { required, helpers } from 'vuelidate/lib/validators'
import errorHandler from '../../../helpers/errorHandler';
const phoneRegex = helpers.regex('phoneNumberRegex',/^01[0-2,5]{1}[0-9]{8}$/);

/**
 * Starter component
 */
export default {
  page: {
    title: "Center Form",
  },
  data() {
    return {
      title: "Center",
      mixins: [validationMixin],
      items: [
        {
          text: "Work center",
          href: "/",
        },
        {
          text: "Centers",
          href: "/centers",
        },
        {
          text: "Edits",
          active: true,
        },
      ],
      submit: false,
      backendError: false,
      type: null,
      form:{
        name: "",
        phone: "",
        address: "",
        id: null
      }
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  validations:{
    form:{
      name: {
        required,
      },
      phone:{
        required,
        phoneRegex
      },
    }
  },
  methods:{
    handleSubmit() {
      this.submit = true;
      this.$v.$touch();
      if(!this.$v.$anyError){
        this.$store.dispatch("center/addCenter",{
          name: this.form.name,
          phone: '2' + this.form.phone,
          address: this.form.address
        }).then(
           ()=>{
            this.$router.push('/centers');
          }
        ).catch((err)=>{
          this.backendError=true;
          errorHandler.methods.error(err);
        });
      }
      
    },
    handleUpdate(){
      this.submit = true;
      this.$v.$touch();
      if(!this.$v.$anyError){
        this.$store.dispatch("center/editCenter",{
          name: this.form.name,
          phone: '2' + this.form.phone,
          address: this.form.address,
          id: this.form.id
        }).then(
           ()=>{
            this.$router.push('/centers');
          }
        ).catch((err)=>{
          this.backendError=true;
          errorHandler.methods.error(err);
        });
      }
    }
  },
  async created(){
    if(this.$route.params.id){
        this.type = 'edit';
        await this.$store.dispatch('center/getCenterInfo',this.$route.params.id).then( token =>{
            this.form.name = token.name;
            this.form.phone = token.phone.toString().substring(1);
            this.form.address = token.address;
            this.form.id = this.$route.params.id;
        }).catch( err =>{
            errorHandler.methods.errorMessage(err);
        })
    }else{
        this.type = 'create';
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <div>
                <div>
                  <div>
                    <h2 v-if="type == 'create'">Create center</h2>
                    <h2 v-if="type == 'edit'">Edit center</h2>
                    <hr>
                  </div>
                <form action="#" @submit.prevent="handleSubmit">
                <div class="row">
                    <div class="col-md-6">
                    <b-form-group
                        id="input-group-2"
                        label="Center Name:"
                        label-for="input-D"
                    >
                        <input v-model="form.name" type="text" name="name" class="form-control" :class="{ 'is-invalid': submit && $v.form.name.$error }" placeholder="Enter center name" />
                        <div v-if="submit && $v.form.name.$error" class="invalid-feedback">
                            <span v-if="!$v.form.name.required">This name is required.</span>
                            <span v-if="!$v.form.name.maxLength">This value should less than 100 char.</span>
                            
                        </div>
                        <div v-if="backendError  " class="text-danger">
                        <span>{{ errorsList.name[0] }}</span>
                        </div>
                    </b-form-group>
                    </div>
                    <div class="col-md-6">
                    <b-form-group
                        id="input-group-2"
                        label="Address :"
                        label-for="input-D"
                    >
                        <b-form-input
                        id="input-C"
                        v-model="form.address"
                        placeholder="Enter center address"
                        ></b-form-input>
                    </b-form-group>
                    </div>
                    <div class="col-md-6">
                    <b-form-group
                        id="input-group-phone"
                        label="Phone:"
                        label-for="input-phone"
                    >
                        <div class="row">
                        <div class="col-xl-12">
                            <b-input-group>
                            <input v-if=" type == 'create' " v-model="form.phone" type="text" name="phone_number" class="form-control" :class="{ 'is-invalid': submit && $v.form.phone.$error }" placeholder="Enter phone number" />
                            <input v-if=" type == 'edit' && form.phone != null" v-model="form.phone" type="text" name="phone_number" class="form-control" :class="{ 'is-invalid': submit && $v.form.phone.$error }" placeholder="Enter phone number" />
                            <div v-if="submit && $v.form.phone.$error" class="invalid-feedback">
                                <span v-if="!$v.form.phone.required">This phone is required.</span>
                                <span v-if="!$v.form.phone.phoneNumberRegex">This value should be phone</span>
                            </div>
                            </b-input-group>
                            <div v-if="backendError" class="text-danger">
                                <span>{{ errorsList['phone_numbers.0'][0] }}</span>
                            </div>
                        </div>
                        </div>
                    </b-form-group>
                    </div>
                </div>
                </form>
                </div>
                <hr />
                <div class="float-right">
                <button class="btn btn-outline-success" type="submit" @click="handleSubmit" v-if="type == 'create'">Submit</button>
                <button class="btn btn-outline-success" type="submit" @click="handleUpdate" v-if="type == 'edit'">Update</button>
                <b-button variant="outline-danger" class="ml-2 aline-right" @click="$router.go(-1)"
                >Cancel</b-button
                >
                </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
#input-group-1 {
  display: inline;
}
</style>